import React from 'react';

export default class ProsperCardPreview extends React.Component {


  _getImage(card) {
    let image = null;
    if (card.category === 'standard') {
      image = require('../../../assets/img/Prosper-Card-Front.png');
    }

    if (card.status === 'inactive' || card.status === 'pending_activation') {
      image = require('../../../assets/img/activate_card.png');
    }

    return image;
  }

  render() {
    let card = this.props.card;
    let pan = card.pan;
    let formatPan = pan.match(/.{1,4}/g);
    let newFormatPan = formatPan[0] + ' ' + formatPan[1] + ' ' + formatPan[2] + ' ' + formatPan[3]

    return (
      <div style={{ position: 'relative', maxWidth: 500 }}>
        <img src={this._getImage(this.props.card)} style={{ width: '100%', height: 'auto', borderRadius: 5 }} alt='card' />
        {/* {card.status === 'active' || 'stolen' || 'locked' ?
          <span className='panNumber'>{newFormatPan}</span>
          : null
        } */}
      </div>
    );
  }
}
