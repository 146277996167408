import React from 'react';

const HomeWidgets: React.FC<any> = props => {
  return <React.Fragment>
    <div className="widget">
      <div className="dark-blur"></div>
      <div className="widget-header">
        <h3 className="widget-title">
          <i className="fa fa-question" aria-hidden="true"></i> TBD
        </h3>
      </div>
      <div className="widget-inner" style={{ padding: '10px' }}>
        <div style={{ width: '100%', height: '100%' }}>
          <canvas id="barChartConfig" style={{ width: '100%', height: '300px' }} width="600" height="300"></canvas>
        </div>
      </div>
    </div>
    <div className="widget">
      <div className="dark-blur"></div>
      <div className="widget-header">
        <h3 className="widget-title">
          <i className="fa fa-question" aria-hidden="true"></i> TBD
          </h3>
      </div>
      <div className="widget-inner" style={{ padding: '10px' }}>
        <div style={{ width: '100%', height: '100%' }}>
          <canvas id="barChartConfig" style={{ width: '100%', height: '300px' }} width="600" height="300"></canvas>
        </div>
      </div>
    </div>
    <div className="widget">
      <div className="dark-blur"></div>
      <div className="widget-header">
        <h3 className="widget-title">
          <i className="fa fa-question" aria-hidden="true"></i> TBD
          </h3>
      </div>
      <div className="widget-inner" style={{ padding: '10px' }}>
        <div style={{ width: '100%', height: '100%' }}>
          <canvas id="barChartConfig" style={{ width: '100%', height: '300px' }} width="600" height="300"></canvas>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default HomeWidgets;
