import React from 'react';
import ProsperCardPreview from './components/ProsperCardPreview';
import OrderCardButton from './components/OrderCardButton';
import pages from './pages';

const overrides = {
  login: {
    page: {
      logo: () => (
        <img
          className="logo"
          src={require('../../assets/img/Samsung-Rewards_logo.svg')}
          alt="samsung-logo"
        />
      ),
      text: () => (
        <h1>
          Welcome to Samsung Rewards{' '}
          <span style={{ display: 'block', textAlign: 'center' }}>
            Administration
          </span>
        </h1>
      )
    }
  },
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img
          src={require('../../assets/img/Samsung-Rewards_logo.svg')}
          alt="logo"
        />
      </div>
    )
  },
  card: {
    preview: (props: any) => <ProsperCardPreview {...props} />
  },
  user: {
    details: {
      beforeContent: (props: any) => <OrderCardButton {...props} />,
      orderCard: (props: any) => <OrderCardButton {...props} />
    }
  },
  pages
};

export default overrides;
