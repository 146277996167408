export const getNavigation = (appNavigation: any) => {

  let navigation = appNavigation.filter(x => x.slug != 'push-notifications' && x.slug != 'kyc');

  let contentManagement = navigation.find(x => x.slug == 'content-management');

  let children = contentManagement.children.filter(x => x.slug != 'faq');

  contentManagement.children = children;

  return navigation;
}

export default {
  getNavigation
}
